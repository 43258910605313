<template>
  <widget-base
    :loading="loading"
    :title="$t('pages.home.widgets.top_categories.title')"
    :has-data="hasData"
    :can-refetch="canRefetch"
    no-data-icon="th-icon-bag"
    @refetch="refetch"
  >
    <el-table
      v-if="hasData"
      class="top-table-color"
      :data="productGroups"
      :show-header="false"
      :border="false"
      height="100%"
    >
      <el-table-column
        type="index"
        class-name="top-table-color-light"
        :resizable="false"
        width="30px"
      />
      <el-table-column prop="productGroup" show-overflow-tooltip resizable />
      <el-table-column
        prop="amount"
        min-width="65"
        resizable
        show-overflow-tooltip
        align="right"
      />
      <el-table-column
        prop="total"
        align="right"
        min-width="30px"
        show-overflow-tooltip
        :resizable="false"
        class-name="top-table-color-light"
      />
    </el-table>
  </widget-base>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import WidgetBase from '../widget-base'

export default {
  name: 'TopCategories',
  components: {
    WidgetBase
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      productGroups: [],
      loading: false,
      canRefetch: false
    }
  },
  computed: {
    selectedDates() {
      return this.currentUserContext.selectedDates
    },
    hasData() {
      return !!this.productGroups.length
    },
    resourceQuery() {
      // global branch filter overrides local branch filter
      let branchNumber
      if (Number.isFinite(this.currentUserContext.currentBranchNumber)) {
        branchNumber = this.currentUserContext.currentBranchNumber
      }

      const query = {
        location: this.currentUserContext.currentLocation || undefined,
        branch_number: branchNumber,
        start: this.selectedDates.start,
        end: this.selectedDates.end
      }

      return query
    }
  },
  watch: {
    selectedDates: function (value) {
      this.updateProductGroups()
    }
  },
  mounted() {
    this.updateProductGroups()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async updateProductGroups() {
      if (!this.selectedDates.start || !this.selectedDates.end) {
        this.productGroups = []
        return
      }

      this.loading = true
      try {
        const { data } = await th
          .analytics({ timeout: 90000 })
          .getProductGroupsReport(this.resourceQuery)
        const { values } = data[0]

        this.productGroups = values
          .filter(
            ({ currency }) =>
              currency === this.currentUserContext.selectedCurrency
          )
          .sort((a, b) => b.amount_gross_total - a.amount_gross_total)
          .slice(0, 5)
          .map((val, i) => ({
            index: i,
            productGroup:
              val.product_group_name ||
              this.$t('pages.reports.statistics.products.null'),
            amount: this.$formatCurrency(val.amount_gross_total, val.currency),
            total: val.transactions_total
          }))
      } catch (err) {
        this.canRefetch = true
        this.productGroups = []
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.refetch()
      })
    },
    refetch() {
      this.updateProductGroups()
      this.canRefetch = false
    }
  }
}
</script>

<style scoped>
.el-table :deep(.top-table-color) {
  color: var(--el-table-text-color-deep);
}

.el-table :deep(.top-table-color-light) {
  color: #a7abb1;
}

/* disable row highlighting on hover */
.el-table :deep(.el-table__body tr:hover > td) {
  background-color: unset;
}
</style>
